<template>
    <div id="My-account-page-container" class="ml-auto mr-auto showroom-background-pattern">
        <h2 class="mt-3 mb-3">Mon compte</h2>
        <nav class="menu-container">
            <div class="btn-container">
                <router-link to="/monCompte/mesAnnonces">
                    <img src="../../../assets/pages-assets/profile-page-assets/my-ads.svg" alt="documents empilés, mène vers vos annonces">
                    <p variant="outline-primary">Mes annonces</p>
                </router-link>
            </div>
            <div class="btn-container">
                <router-link to="/monCompte/mesInformations">
                    <img src="../../../assets/pages-assets/profile-page-assets/user.svg" alt="représentation de l'utilisateur, mène vers les informations du profile">
                    <p variant="outline-primary">Mes informations</p>
                </router-link>
            </div>
            <div class="btn-container">
                <router-link to="/favoris">
                    <img src="../../../assets/pages-assets/profile-page-assets/heart.svg" alt="coeur, mène vers mes favoris">
                    <p variant="outline-primary">Mes favoris</p>
                </router-link>
            </div>
            <div class="btn-container">
                <router-link to="/">
                    <img src="../../../assets/pages-assets/profile-page-assets/legal.svg" alt="balance de justice, mène vers les informations légal">
                    <p variant="outline-primary">Information légales</p>
                </router-link>
            </div>
            <div class="btn-container">
                <router-link to="/">
                    <img src="../../../assets/pages-assets/profile-page-assets/private.svg" alt="cadenas, mène vers la page données privées ">
                    <p variant="outline-primary">Données privées</p>
                </router-link>
            </div>
             <div class="btn-container">
                 <router-link to="/monCompte" @click.native="deconnexion()" >
                    <img src="../../../assets/pages-assets/profile-page-assets/exit.svg" alt="sortie, déconnecte l'utilisateur ">
                    <p variant="outline-primary">Se déconnecter</p>
                </router-link>
            </div>
        </nav>
    </div>
</template>

<script>
import AuthService from '../../../services/AuthService'
import router from '../../../router'
export default {
    name: "MyAccountPage",
    components: {
    },
    created(){
        this.$emit('headerState', 2);
    },

    methods : {
        deconnexion(){
            AuthService.signOutUser().then(
                () => {
                    console.info("deconnexion reussie !");
                    router.push('connexion');
                },
                (error) => {
                    this.errorMessage = error;
                    console.error("deconnexion echouee : " + error);
                }
            );
        },
    }
};
</script>

<style lang="scss">
    @import "my-account-page.scss";
</style>